















import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import FooterBlock from '@/components/FooterBlock.vue'
import ContactBlock from '@/components/ContactBlock.vue'
import QuestionBlock from '@/components/QuestionBlock.vue'
import HelpBlock from '@/components/HelpBlock.vue'

import Banner from './components/Banner.vue'

import { scrollToElem } from '@/utils/scroll'

@Component({
  components: {
    Banner,
    FooterBlock,
    ContactBlock,
    QuestionBlock,
    HelpBlock
  },
})
export default class Sale extends Vue {
  showContact = true

  bannerMap = {
    'deliveryPlatform': {
      imgH5: require('@/assets/sale/delivery-platform-h5.png'),
      imgPc: require('@/assets/sale/delivery-platform-pc.png'),
      alt: '一部POS管理所有外賣平臺訂單',
    },
    member: {
      imgH5: require('@/assets/sale/member-h5.png'),
      imgPc: require('@/assets/sale/member-pc.png'),
      alt: '強大會員系統提升顧客的忠誠度'
    },
    discount: {
      imgH5: require('@/assets/sale/discount-h5.png'),
      imgPc: require('@/assets/sale/discount-pc.png'),
      alt: '強大的營銷功能，幫助餐廳提升營業額'
    },
    bigScreen: {
      imgH5: require('@/assets/sale/big-screen-h5.png'),
      imgPc: require('@/assets/sale/big-screen-pc.png'),
      alt: '取餐大屏幕，餐品訂單製作狀態，一目了然'
    },
    miniProgram: {
      imgH5: require('@/assets/sale/mini-program-h5.png'),
      imgPc: require('@/assets/sale/mini-program-pc.png'),
      alt: '免費升級"餐飲小程式"'
    }
  }

  get bannerData() {
    return this.bannerMap[this.$route.name]
  }

  get isH5() {
    return AppModule.isH5
  }

  contactHeight = 0
  footerHeight = 0
  screenHeight = 0
  bodyHeight = 0

  mounted() {
    this.contactHeight = (this.$refs.contact as any).$el.offsetHeight
    this.footerHeight = (this.$refs.footer as any).$el.offsetHeight
    this.screenHeight = document.documentElement.clientHeight
    window.addEventListener('scroll', this.scrollHandle)
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandle)
  }

  scrollHandle() {
    const scrollTop = document.documentElement.scrollTop
    if (!this.bodyHeight) {
      this.bodyHeight = document.body.offsetHeight
    }
    if (scrollTop >= this.bodyHeight - this.screenHeight - this.footerHeight - this.contactHeight) {
      this.showContact = false
    } else {
      this.showContact = true
    }
  }

  scrollToContact() {
    scrollToElem('#contact', 500, 80)
  }
}
